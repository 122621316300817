/*global Component, wrapPost */
class genericIndicatorComponent extends Component {

    static name() {
        return "indicatorComponent";
    }

    static componentName() {
        return "indicatorComponent";
    }

    getProps() {
        return {
            indicator:{
                type:Object,
                required:true
            },
        };
    }

    data() {
        return {
            isLoading:true,
            params:{},
            report:null
        };
    }

    getComputed() {
        return {
            reportTables:function () {
                if(this.report)
                    return Object.keys(this.report);
                return [];
            }
        };
    }

    getMethods() {
        return {
            getReportName:this.getReportName,
            runReport: this.runReport,
        };
    }

    getReportName(){
        return this.indicator.fields.ReportName;
    }

    runReport(parameters){
        let self = this;
        if(!parameters)
            parameters = {};
        Object.assign(parameters,{run_kpi:true,kpiView:self.indicator.fields.kpiDetail});
        return new Promise((resolve, reject) => {
            self.isLoading = true;
            self.report = null;
            $(".card-reveal").attr('style','');
            wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/runReport`, {
                reportName: self.getReportName(),
                params: parameters,
            })
                .then(function (response) {
                    self.report = Object.freeze(response.data.report);
                    self.isLoading = false;
                    resolve(true);
                })
                .catch(function (error) {
                    self.isLoading = false;
                    $(".card-reveal").attr('style','');
                    reject(false);
                });
        });
    }

    getTemplate() {
        return `<div class="indicatorCard col s12 m6" :ref="'indicatorContainer-'+getReportName()">
                 <div class="card large">
                     <div class="card-content">
                          <span class="card-title activator grey-text text-darken-4 row">
                            <p class="col s12">
                                {{tr(indicator.fields.kpiDetail)}}
                            </p>    
                          </span>
                          <slot name="parameters" :runAction="runReport" :params="params" :loading="isLoading"></slot> 
                          <template v-if="isLoading" >
                             <div class="col s12 container center section white" style="min-height: 60%;" >
                                <div class="preloader-wrapper big active">
                                      <div class="spinner-layer spinner-blue">
                                        <div class="circle-clipper left">
                                          <div class="circle"></div>
                                        </div><div class="gap-patch">
                                          <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                          <div class="circle"></div>
                                        </div>
                                      </div>
                                
                                      <div class="spinner-layer spinner-red">
                                        <div class="circle-clipper left">
                                          <div class="circle"></div>
                                        </div><div class="gap-patch">
                                          <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                          <div class="circle"></div>
                                        </div>
                                      </div>
                                
                                      <div class="spinner-layer spinner-yellow">
                                        <div class="circle-clipper left">
                                          <div class="circle"></div>
                                        </div><div class="gap-patch">
                                          <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                          <div class="circle"></div>
                                        </div>
                                      </div>
                                
                                      <div class="spinner-layer spinner-green">
                                        <div class="circle-clipper left">
                                          <div class="circle"></div>
                                        </div><div class="gap-patch">
                                          <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                          <div class="circle"></div>
                                        </div>
                                      </div>
                                </div>
                                <h4>{{tr('Page loading please wait')}}</h4>
                             </div>
                          </template>
                          <template v-else>
                                 <div :key="'indicatorRenderContainer-'+getReportName()" id="genericIndicatorView" :ref="'reportRenderContainer-'+getReportName()" class="indicatorRenderContainer col s12" >
                                      <template v-if="reportTables.length>0" v-for="table in reportTables">
                                            <reportTableView :table="Object.freeze(report[table])" :key="getReportName()+table" :tablename="table"></reportTableView>
                                      </template>
                                 </div>           
                          </template>
                    </div>
                 </div>  
             </div>`;
    }
}

genericIndicatorComponent.registerComponent();

